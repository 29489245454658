const url = '/dashboard/'
export default {
    // Columnas que se usarán en los filtros
    getColumnsForFilter: (axios) => {
        return axios.get(`${url}columns_for_filter/`)
            .then(response => { return { data: response.data } })
            .catch(error => { return { error: error?.response?.data } });
    },
    // Operaciones permitidas por tipo de dato
    getOperationsForType: (axios, type_name) => {
        return axios.get(`${url}operator_types/${type_name}/`)
            .then(response => { return { data: response.data } })
            .catch(error => { return { error: error?.response?.data } });
    },
    // Listas para las columnas tipo select
    getDataForFilter: (axios, data) => {
        return axios.get(`${url}data_for_fields_filter/${data}/`)
            .then(response => { return { data: response.data } })
            .catch(error => { return { error: error?.response?.data } });
    },
    // Aplicar filtro
    applyFilters: (axios, signature, data) => {
        return axios.post(`${url}complaints/`, data, {
            headers: { "X-SFC-Signature": signature(data) }
        })
            .then(response => { return { data: response.data } })
            .catch(error => { return { error: error?.response?.data } });
    },
    // Datos de volumetría
    getVolumetry: (axios, signature, data) => {
        return axios.post(`${url}volumetry/`, data, {
            headers: { "X-SFC-Signature": signature(data) }
        })
            .then(response => { return { data: response.data } })
            .catch(error => { return { error: error?.response?.data } });
    },
    // Datos de la productividad
    getProductivity: (axios, signature, name_filter, data) => {
        return axios.post(`${url}complaint_productivity/${name_filter}/`, data, {
            headers: { "X-SFC-Signature": signature(data) }
        })
            .then(response => { return { data: response.data } })
            .catch(error => { return { error: error?.response?.data } });
    },
    // Datos de satisfacción
    getSatisfaction: (axios, signature, data) => {
        return axios.post(`${url}complaint_satisfaction/`, data, {
            headers: { "X-SFC-Signature": signature(data) }
        })
            .then(response => { return { data: response.data } })
            .catch(error => { return { error: error?.response?.data } });
    },
    // Datos de tiempo de respuesta
    getResponseTime: (axios, signature, data) => {
        return axios.post(`${url}complaint_response_time/`, data, {
            headers: { "X-SFC-Signature": signature(data) }
        })
            .then(response => { return { data: response.data } })
            .catch(error => { return { error: error?.response?.data } });
    },
    // Quejas para la tabla
    searchComplaints: (axios, signature, data) => {
        return axios.post(`${url}complaint_search/`, data, {
            headers: { "X-SFC-Signature": signature(data) }
        })
            .then(response => { return { data: response.data } })
            .catch(error => { return { error: error?.response?.data } });
    },
}