
export default [

    {
        path: 'management/report',
        component: () => import('@/views/dashboard/management/Management'),
        meta: {
            title: 'Dashboard reporte general',
            breadcrumb: [
                { label: 'Inicio', link: '/' },
                { label: 'Dashboard / Reporte', link: '/dashboard/management/report' },
            ]
        },

    },
    {
        path: 'management/fraud',
        component: () => import('@/views/dashboard/management/Fraude'),
        meta: {
            title: 'Dashboard fraude',
            breadcrumb: [
                { label: 'Inicio', link: '/' },
                { label: 'Dashboard / Fraude', link: '/dashboard/management/fraud' },
            ]
        },

    },
    {
        path: 'management/favorability',
        component: () => import('@/views/dashboard/management/Favorability'),
        meta: {
            title: 'Dashboard favorabilidad',
            breadcrumb: [
                { label: 'Inicio', link: '/' },
                { label: 'Dashboard / Favorabilidad', link: '/dashboard/management/favorability' }
            ]
        },

    },
    {
        path: 'management/productivity',
        component: () => import('@/views/dashboard/management/Productivity'),
        meta: {
            title: 'Dashboard productividad',
            breadcrumb: [
                { label: 'Inicio', link: '/' },
                { label: 'Dashboard / Productividad', link: '/dashboard/management/productivity' }
            ]
        },

    },
    {
        path: 'management/complaints',
        component: () => import('@/views/dashboard/management/SearchComplaint'),
        meta: {
            title: 'Dashboard quejas',
            breadcrumb: [
                { label: 'Inicio', link: '/' },
                { label: 'Dashboard / Búsqueda avanzada', link: '/dashboard/management/complaints' }
            ]
        },

    },
    {
        path: 'management/map',
        component: () => import('@/views/dashboard/management/Map'),
        meta: {
            title: 'Dashboard mapa',
            breadcrumb: [
                { label: 'Inicio', link: '/' },
                { label: 'Dashboard / Mapa', link: '/dashboard/management/map' }
            ]
        },

    },
];